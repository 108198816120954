<template>
  <div class="d-flex flex-column flex-fill">
    <hsLoading v-if="isLoading" class="flex-fill" />
    <section v-else class="position-relative py-1 px-3 py-lg-4 px-lg-5 mt-5 d-flex flex-column">
      <section class="d-flex w-100 details-members-container">
        <div>
          <div class="d-flex w-75">
            <b-img-lazy
              id="logo-sparkfunnels"
              width="237"
              fluid
              :src="require('@/assets/images/logos/sparkfunnels.svg')"
            />
          </div>
          <p id="title-detail" class="mt-5 mb-2 font-weight-bold text-uppercase font-size-xs text-secondary">
            {{ $t('landing-page.views.sparkfunnels-landing-page.index.title-detail') }}
          </p>
          <h4 id="title" class="title-text font-weight-bold mt-4 pb-3">
            {{ $t('landing-page.views.sparkfunnels-landing-page.index.title') }}
          </h4>
          <p id="sub-title">
            {{ $t('landing-page.views.sparkfunnels-landing-page.index.sub-title') }}
          </p>

          <div class="d-flex justify-content-center justify-content-lg-start">
            <hs-button variant="cherry" class="btn-ensure-access mt-4" size="lg" @click="enableSparkFunnelsFeature">
              <b>{{ $t('landing-page.views.sparkfunnels-landing-page.index.buttons.btn-ensure-access') }}</b>
            </hs-button>
          </div>
        </div>
        <div class="d-none d-lg-block">
          <b-img-lazy
            id="info-products-image"
            class="position-absolute"
            :src="require('@/assets/images/funnel/landing-page-ilustration.svg')"
            width="300"
          />
        </div>
      </section>
      <section class="my-4 w-100">
        <b-row class="mx-0">
          <b-col sm="12" md="6" xl="4" class="mt-5 p-0">
            <InformationCard
              :title="$t('landing-page.views.sparkfunnels-landing-page.index.information-card.card1.title')"
              :subtitle="$t('landing-page.views.sparkfunnels-landing-page.index.information-card.card1.sub-title')"
              icon="window-restore"
              background="#00DFED"
            />
          </b-col>
          <b-col sm="12" md="6" xl="4" class="mt-5 p-0">
            <InformationCard
              :title="$t('landing-page.views.sparkfunnels-landing-page.index.information-card.card2.title')"
              :subtitle="$t('landing-page.views.sparkfunnels-landing-page.index.information-card.card2.sub-title')"
              icon="envelope"
              background="#7427F1"
            />
          </b-col>
          <b-col sm="12" md="6" xl="4" class="mt-5 p-0">
            <InformationCard
              :title="$t('landing-page.views.sparkfunnels-landing-page.index.information-card.card3.title')"
              :subtitle="$t('landing-page.views.sparkfunnels-landing-page.index.information-card.card3.sub-title')"
              icon="chart-line"
              background="#FF305C"
            />
          </b-col>
        </b-row>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import toastHelper from '@/shared/helpers/toast';
import { analyticsService, customerService } from '@/services';

export default {
  name: 'SparkFunnelsLandingPage',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('school', {
      school: state => state.selectedSchool,
    }),
  },
  components: {
    InformationCard: () => import('./components/InformationCard'),
    hsLoading: () => import('@/components/Loading'),
  },
  methods: {
    ...mapActions('school', ['enableFeature']),
    async enableSparkFunnelsFeature() {
      this.isLoading = true;
      try {
        await this.enableFeature({
          featureId: parseInt(process.env.VUE_APP_SPARKFUNNELS_FEATURE_ID),
          featureKey: 'spark_funnel',
        });

        analyticsService.track({
          event: 'SparkFunnels activated',
        });

        this.$router.push({ name: 'FunnelsList' });
      } catch {
        toastHelper.dangerMessage(
          this.$t('landing-page.views.sparkfunnels-landing-page.index.toast-messages.feature-activation-error')
        );
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-ensure-access {
  width: 277px;
  height: 80px;
  font-size: 20px;
}

#info-products-image {
  width: 560px;
  height: 548px;
  right: 0px;
  top: -100px;
}

@media only screen and (min-width: $screen-md) {
  .title-text {
    font-size: 30px;
  }

  .details-members-container {
    width: 40% !important;
  }
}
</style>
